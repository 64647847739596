import { lazy } from "react";

const pages = {
  Page404: lazy(() => import("./404")),
  // Privacy Policy
  Privacy: lazy(() => import("./Privacy")),
  // Login
  Login: lazy(() => import("./Login")),
  Register: lazy(() => import("./Register")),
  ForgotPassword: lazy(() => import("./ForgotPassword")),
  //  Warehouse
  Warehouse: lazy(() => import("./Warehouse/index")),
  WHProductDetails: lazy(() => import("./Warehouse/WHProductDetails")),
  StockIn: lazy(() => import("./Warehouse/StockIn")),
  CreateNewProduct: lazy(() => import("./Warehouse/CreateNewProduct")),
  //Stock
  Stock: lazy(() => import("./Stock/index")),
  StockTakeList: lazy(() => import("./Stock/StockTakeList")),
  StockTake: lazy(() => import("./Stock/StockTake")),
  // Stock Adjustment
  StockAdjustment: lazy(() => import("./StockAdjustment/index")),
  CreateStockAdjusment: lazy(() =>
    import("./StockAdjustment/ManageStockAdjustment")
  ),
  ManageStockAdjustment: lazy(() =>
    import("./StockAdjustment/ManageStockAdjustment")
  ),
  ViewStockAdjustment: lazy(() =>
    import("./StockAdjustment/ViewStockAdjustment")
  ),
  // Subwarehouse/Serviceman
  SubWarehouse: lazy(() => import("./SubWarehouse/index")),
  ServicemanDetails: lazy(() => import("./SubWarehouse/ServicemanDetails")),
  CreateServiceman: lazy(() => import("./SubWarehouse/CreateServiceman")),
  SubChangePassword: lazy(() => import("./SubWarehouse/ChangePassword")),
  // Customer
  Customer: lazy(() => import("./Customer")),
  CreateNewCustomer: lazy(() => import("./Customer/CreateNewCustomer")),
  CustomerDetails: lazy(() => import("./Customer/CustomerDetails")),
  // Resupply
  Resupply: lazy(() => import("./Resupply")),
  // Supplier
  Suppliers: lazy(() => import("./Suppliers")),
  CreateSupplier: lazy(() => import("./Suppliers/CreateNewSupplier")),
  SupplierDetails: lazy(() => import("./Suppliers/SupplierDetails")),
  TaxRates: lazy(() => import("./Suppliers/TaxRates")),
  // Purchase Order
  PurchaseOrder: lazy(() => import("./PurchaseOrder/index")),
  CreatePurchaseOrder: lazy(() =>
    import("./PurchaseOrder/CreatePurchaseOrder")
  ),
  ManagePurchaseOrder: lazy(() =>
    import("./PurchaseOrder/ManagePurchaseOrder")
  ),
  OrderDetails: lazy(() => import("./PurchaseOrder/OrderDetails")),
  // Goods Received
  GoodsReceived: lazy(() => import("./GoodsReceived/index")),
  ManageGoodsReceived: lazy(() =>
    import("./GoodsReceived/ManageGoodsReceived")
  ),
  // Vending Machine
  VendingMachines: lazy(() => import("./VendingMachines")),
  CreateNewVM: lazy(() => import("./VendingMachines/CreateNewVM")),
  VMDetails: lazy(() => import("./VendingMachines/VMDetails")),
  ManageInventory: lazy(() => import("./VendingMachines/ManageInventory")),
  ManageInventoryV2: lazy(() => import("./VendingMachines/ManageInventoryV2")),
  ViewIotDevices: lazy(() => import("./VendingMachines/ViewIotDevices")),
  // Jobs
  Jobs: lazy(() => import("./Jobs")),
  JobsDetails: lazy(() => import("./Jobs/JobDetails")),
  // Sales
  Transactions: lazy(() => import("./Sales/Transactions")),
  Transactions_Import: lazy(() => import("./Sales/Transactions_Import")),
  // Cash Reconcilication
  Cash: lazy(() => import("./Cash/indexV2")),
  // Route
  Route: lazy(() => import("./Route")),
  CreateRoute: lazy(() => import("./Route/CreateRoute")),
  ModalEditRoute: lazy(() => import("./Route/ModalEditRoute")),
  ViewMachine: lazy(() => import("./Route/ViewMachine/ViewMachine")),
  SubRouteInventory: lazy(() => import("./Route/SubRouteInventory")),
  SubWarehouseStock: lazy(() => import("./Route/SubWarehouseStock")),
  SubWarehouseStockTakeList: lazy(() =>
    import("./Route/SubWarehouseStockTakeList")
  ),
  SubWarehouseStockTake: lazy(() => import("./Route/SubWarehouseStockTake")),
  // Reports
  Reports: lazy(() => import("./Reports/index")),
  ChartReports: lazy(() => import("./Reports/chart_index")),
  // User Account
  Account: lazy(() => import("./User/Account/index.js")),
  CreateAccount: lazy(() => import("./User/Account/CreateAccount")),
  EditAccount: lazy(() => import("./User/Account/EditAccount")),
  ChangePassword: lazy(() => import("./User/Account/ChangePassword")),
  // User Role
  Role: lazy(() => import("./User/Role/index")),
  ManageRole: lazy(() => import("./User/Role/ManageRole")),
  CreateRole: lazy(() => import("./User/Role/CreateRole")),
};

export default pages;

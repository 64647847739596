
export const getConfig = async (env_var) => {
  try {
    const response = await fetch('/config.json'); // fetch from /config.js

    if (response.ok) {
      const data = await response.json();
      return data[env_var];
    } else {
      console.error('Failed to fetch env.json');
      return {};
    }
  } catch (error) {
    console.error('Error loading env.json:', error);
    return {};
  }
};

